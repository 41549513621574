import React from 'react';
import { Instagram, Plane, Train, Building, Shield } from 'lucide-react';

const TikTokIcon = ({ size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5" />
  </svg>
);

const ProfilePage = () => {
  // Configuration des boutons avec leurs URLs
  const buttons = [
    {
      name: 'Instagram',
      icon: <Instagram size={20} />,
      url: 'https://instagram.com/flo_et_theo?igsh=MWtlMnBrMmF5eXhkZQ%3D%3D&utm_source=qr'  // À modifier
    },
    {
      name: 'Tiktok',
      icon: <TikTokIcon size={20} />,
      url: 'https://tiktok.com/@theo_log'  // À modifier
    },
    {
      name: 'Trip.com',
      icon: <Plane size={20} />,
      url: 'https://trip.com/t/Ybexwe2QjY1'  // À modifier
    },
    {
      name: '12Go',
      icon: <Train size={20} />,
      url: 'https://12go.com/?z=10023206'  // À modifier
    },
    {
      name: 'Agoda',
      icon: <Building size={20} />,
      url: 'https://agoda.com/partners/partnersearch.aspx?pcs=1&cid=1929416&hl=fr-fr'  // À modifier
    },
    {
      name: 'ACS Assurances',
      icon: <Shield size={20} />,
      url: 'https://acs-ami.com/?part=webolog'  // À modifier
    },
  ];

  // Configuration des icônes sociales avec leurs URLs
  const socialIcons = [
    {
      icon: <Instagram size={24} />,
      url: 'https://instagram.com/flo_et_theo?igsh=MWtlMnBrMmF5eXhkZQ%3D%3D&utm_source=qr'  // À modifier
    },
    {
      icon: <TikTokIcon size={24} />,
      url: 'https://tiktok.com/@theo_log'  // À modifier
    },
    {
      icon: <Shield size={24} />,
      url: 'https://acs-ami.com/?part=webolog'  // À modifier
    },
  ];

  return (
    <div className="min-h-screen w-full bg-gradient-to-b from-orange-300 via-orange-400 to-gray-900 flex flex-col items-center pt-12 px-4 pb-6">
      {/* Profile Image */}
      <div className="w-24 h-24 rounded-full overflow-hidden mb-4">
        <img
          src="/api/placeholder/96/96"
          alt="Profile"
          className="w-full h-full object-cover"
        />
      </div>

      {/* Name and Description */}
      <h1 className="text-white text-2xl font-semibold mb-1">Theolog</h1>
      <p className="text-white/90 text-sm mb-8">Mes liens affiliés</p>

      {/* Navigation Buttons */}
      <div className="w-full max-w-sm space-y-3">
        {buttons.map((button) => (
          <a
            key={button.name}
            href={button.url}
            target="_blank"
            rel="noopener noreferrer"
            className="w-full bg-white rounded-full py-3 px-6 font-medium hover:bg-gray-100 transition-colors flex items-center justify-center gap-2 no-underline text-gray-800"
          >
            {button.icon}
            {button.name}
          </a>
        ))}
      </div>

      {/* Social Icons */}
      <div className="flex space-x-6 mt-12">
        {socialIcons.map((social, index) => (
          <a
            key={index}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-white hover:text-gray-200 transition-colors"
          >
            {social.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default ProfilePage;
